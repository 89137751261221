import React from 'react';
import avatarnft from '../../assets/avatarnft.png';
import './possibility.css';

const Possibility = () => (
  <div className="avatar__possibility section__padding" id="Nft development">
    <div className="avatar__possibility-image">
      <img src={avatarnft} alt="avatar nft" />
    </div>
    <div className="avatar__possibility-content">
      <h4><a href="#contact">Request An Appointment to Get Started</a></h4>
      <h1 className="gradient__text">The possibilities are <br /> beyond your imagination</h1>
      <p>We can create brand identity, totally fits your community and integrated into web3.0 solutions. whether you are an artist and you want to start earn royalties on your art, or a game developper, we definetly take your project to the next level, with NFT integration and a marketplace just like opensea, start earning from your assets online now.</p>
      <h4>It's still early, be the first to integrate nft in your business</h4>
    </div>
  </div>
);

export default Possibility