import React from 'react';
import quanturamalogo from '../../assets/qsatlogoblack.png';
import './footer.css';

const Footer = () => (
  <div className="qsat__footer section__padding" id="contact">
    <div className="qsat__footer-heading">
      <h1 className="gradient__text">Do you want to step into the future before others</h1>
    </div>
    <a href="https://t.me/quanturama" target="_blank" rel="noopener noreferrer">
  <div className="qsat__footer-btn">
    <p>Request Chat Now</p>
  </div>
</a>

    <div className="qsat__footer-links">
      <div className="qsat__footer-links_logo">
        <img src={quanturamalogo} alt="qsat_logo" />
        <p> <br /> </p>
      </div>
      <div className="qsat__footer-links_div">
        <h4>Follow Us</h4>
        <a href="https://t.me/quanturama" target="_blank" rel="noopener noreferrer">Telegram</a>
        <a href="https://twitter.com/quanturama" target="_blank" rel="noopener noreferrer">Twitter</a>
        <a href="https://www.instagram.com/Quanturamabtc/" target="_blank" rel="noopener noreferrer">Instagram</a>
      </div>
      <div className="qsat__footer-links_div">
        <h4>DAO</h4>
        <p>Decentralized Autonomous</p>
        <p>Organization</p>
        
      </div>
      <div className="qsat__footer-links_div">
        <h4>Open a Chat</h4>
        <a href="https://t.me/quanturama" target="_blank" rel="noopener noreferrer">
          Here 💬
        </a>
        <p href="https://t.me/quanturama" target="_blank" rel="noopener noreferrer">
      </p>
        <p>info@quanturama.xyz</p>
      </div>
    </div>

    <div className="qsat__footer-copyright">
      <p>@2021 Quanturama. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
