import React from 'react';
import Feature from '../../components/feature/Feature';
import './service.css';

const Service = () => (
  <div className="nft__service section__margin" id="service">
    <div className="nft__service-feature">
      <Feature
        title="What We Offer"
        text="Explore the comprehensive range of NFT services and development solutions we offer, designed to unlock the full potential of digital assets. Our expertise spans the creation, management, and marketing of NFTs, empowering artists, creators, and collectors alike. With our innovative tools and technologies, you can showcase and sell unique digital artwork, collectibles, music, videos, and more. Seamlessly navigate the world of NFTs and harness their value on secure blockchain platforms. Discover how our services can help you thrive in the realm of digital ownership and creativity."
      />
    </div>
    <div className="nft__service-heading">
      <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
      <p>
        <a href="#blog">Explore the Library</a>
      </p>
    </div>
    <div className="nft__service-container">
      <Feature
        title="NFT Art Generating"
        text="With our collaborations in design and software development, we can tailor an art collection with a design matching your brand to tokenize digital art and help you start generating assets through royalties. "
      />
      <Feature
        title="NFT Minting Dapps"
        text="build and deploy minting dapps platforms for the process of minting non-fungible tokens and releasing them to the blockchain, by your communities."
      />
      <Feature
        title="NFT Marketplace Platforms"
        text="We will build a marketplace that will make managing items, 'buy sell or trade' via non-fungible tokens easy, effective, and accessible to a broad audience, either to list your Nfts or integrated into your app, the possibilities are endless"
      />
    </div>
  </div>
);

export default Service;
