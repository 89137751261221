import React from 'react';
import './cta.css';

const Cta = () => (
  <div className="quanturama__cta">
    <div className="quanturama__cta-content">
      <p>Let's discuss your future project</p>
      <h3>Join Lobby & start exploring the endless possibilities.</h3>
    </div>
    <div className="quanturama__cta-btn">
    <a href="https://t.me/QuanturamaLobby" target="_blank" rel="noopener noreferrer">
      <button type="button">Get Started</button>
      </a>
    
    </div>
  </div>
);

export default Cta;
