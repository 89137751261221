import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports'; 
import './blog.css'

const Blog = () => (
  <div className="crypto__blog section__padding" id="blog">
    <div className="crypto__blog-heading">
      <h1 className="gradient__text">A lot is happening, <br /> We are blogging about it.</h1>
    </div>
    <a href='https://quanturama.blog/' target="_blank" rel="noopener noreferrer">
    <div className="crypto__blog-container">
      <div className="crypto__blog-container_groupA">
        <Article imgUrl={blog01} date="Sep 26, 2021" text="A virtual world is all we need, and NFT is here to tokenize everything." />
      </div>
      
      <div className="crypto__blog-container_groupB">
        <Article imgUrl={blog02} date="Sep 26, 2020" text="NFT and Open  AI is the future. Let us explore how it is?" />
        <Article imgUrl={blog03} date="Dec 24, 2021" text="Take your Brand to the next level with nfts. Let us find out how." />
        <Article imgUrl={blog04} date="May 02, 2022" text="NFT in the gaming industry. How to profit from NFTs?" />
        <Article imgUrl={blog05} date="March 03, 2023" text="Web 3.0 is the future. Let's define the differences between Web 2.0 & web 3.0" />
      </div>
    </div>
    </a>
  </div>
);
export default Blog