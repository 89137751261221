import React from 'react';
import './header.css';
import nftpeople from '../../assets/nftpeople.png'
import quanturamai from '../../assets/quanturamai.png'

const Header = () => {
  return (
    <div className='quanturama__header section__padding'id='home'>
      <div className='quanturama__header-content'>
        <h1 className='gradient__text'>WEB 3.0 Development Services</h1>
        <p>We provide services such as NFT Marketplace Development, NFT Smart Contract Programming &  NFT Token Development Services. We help NFT artists, creators, and SMEs develop robust NFT marketplace platforms in a safe and secure manner.</p>
        <div className="quanturama__header-content__input">
        <input type="email" placeholder="My Project Azuki" />
        <button type="button">Get Started</button>
      </div>
      <div className="quanturama__header-content__people">
        <img src={nftpeople} alt ="Nft users" />
             <p>25,000 people only are in NFT space</p>
      </div>
    </div>
    <div className="quanturama__header-image">
      <img src={quanturamai} alt='quanturama logo' />
      </div>
    </div>
  )
}

export default Header