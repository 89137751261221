import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.png';
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className='quanturama__navbar'>
      <div className='quanturama__navbar-links'>
        <div className='quanturama__navbar-links_logo'>
          <img src={logo} alt='logo' /> 
        </div>
        <div className='quanturama__navbar-links_container'>
          <p>
            <a href='#home'>Home</a>
          </p>
          <p>
            <a href='#service'>Services</a>
          </p>
          <p>
            <a href='#contact'>Contact us</a>
          </p>
          <p>
            <a href='#features'>Feature</a>
          </p>
          <p>
            <a href='#blog'>Librairy</a>
          </p>
        </div>
      </div>
      <div className="quanturama__navbar-sign">
        <p>Connect</p>
        <button type="button">Web 3.0</button>
      </div>
      <div className='quanturama__navbar-menu'>
        {toggleMenu ? (
          <RiCloseLine
            color='#fff'
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color='#fff'
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className='quanturama__navbar-menu_container scale-up-center'>
            <div className='quanturama__navbar-menu_container-links'>
              <p>
                <a href='#home'>Home</a>
              </p>
              <p>
                <a href='#service'>NFT Development</a>
              </p>
              <p>
                <a href='#contact'>Contact us</a>
              </p>
              <p>
                <a href='#features'>Tools</a>
              </p>
              <p>
                <a href='#blog'>Blog</a>
              </p>
            </div>
            <div className="quanturama__navbar-menu_container-links-sign">
            <p>Sign in</p>
            <button type="button">Sign up</button>
           </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
