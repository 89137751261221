import React from 'react';
import { alchemynft, ipfs, matic, openzepplin, ethereumlogo } from './imports';
import './brand.css';

const Brand = () => {
  return (
    <div className='quanturama__brand section__padding'>
      <div>
        <img src={alchemynft} alt="smartcontract" />
      </div>
      <div>
        <img src={ipfs} alt='ipfs' />
      </div>
      <div>
        <img src={matic} alt= ' maticnft' />
      </div>
      <div>
        <img src={openzepplin} alt="openzepplin" />
      </div>
      <div>
        <img src={ethereumlogo} alt="ethereum nft" />
      </div>
    </div>
  );
};

export default Brand;
