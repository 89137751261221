import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'NFT Art Engine',
    text: 'Generate and deploy up to 10,000 unique variations of your artwork and benefit from our AI Engine designed to generate images, faster than ever before',
  },
  {
    title: 'NFT Minting Dapps',
    text: 'Create a Land Page with a minting dapp to let your community mint your NFT Collection',
  },
  {
    title: 'NFT Gateway',
    text: 'Turn your Web 2.0 website into a Web 3.0, through metamask integration, galleries & many more features',
  },
  {
    title: 'Education & Security Prevention',
    text: 'We set you up just enough to get you started into blockchain, cryptocurrencies, Nfts, wallets & securities',
  },
];

const Features = () => (
  <div className="nft__features section__padding" id="features">
    <div className="nft__features-heading">
      <h1 className="gradient__text">The Future is Now and You Just Need to Realize It. Step into Future Today. & Make it Happen.</h1>
      <p><a href="#contact">Open a Chat to Get Started</a></p> 
    
    </div>
    <div className="nft__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;